import {
  Input,
  Component,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { AttachmentFileDetails } from 'src/app/core/models/common/AttachmentFormObject';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';

@Component({
  selector: 'ds-service-attachments',
  templateUrl: './ds-service-attachments.component.html',
})
export class DsServiceAttachmentsComponent<T>
  extends BaseComponent
  implements OnChanges
{
  showValidation: boolean;
  readonly MAX_COUNT_OF_ATTACHMENTS = 1;
  readonly MAX_FILE_SIZE_BYTE: number = 5 * 1024 * 1024; ///5MB

  @Input()
  attachments: IDsServiceAttachment[] = [];

  @Output()
  fileSelected = new EventEmitter();

  @Output()
  remove = new EventEmitter();

  constructor(private fb: FormBuilder, translateService: TranslateService) {
    super(translateService);
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {}

  public onSelectFile(
    event: NgxFileDropEntry[],
    attachment: IDsServiceAttachment
  ): void {
    for (const droppedFile of event) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.emitSelectFile(file, attachment);
        });
      }
    }
  }

  private emitSelectFile(file: File, attachment: IDsServiceAttachment) {
    this.fileSelected.emit({
      file: file,
      attachment: attachment,
    });
  }

  public removeFileInstance(
    attachment: IDsServiceAttachment,
    instance: AttachmentFileDetails
  ) {
    this.remove.emit({
      instance: instance,
    });
  }

  public onUploadButtonClicked(func: any): void {
    func();
  }

  public link(file: AttachmentFileDetails) {
    return file.filePath;
  }
}

export interface IDsServiceAttachment {
  nameAr: string;
  nameEn: string;
  isRequired: boolean;

  ///es class used here as a start point to unify
  instances?: AttachmentFileDetails[];

  identity: string | number;
}
