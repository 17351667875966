<div *ngFor="let attachment of attachments; let i = index">
  <label
    class="form-control-label"
    [ngClass]="{ 'form-control-label-required': attachment.isRequired }"
    >{{ isArabic ? attachment.nameAr : attachment.nameEn }}</label
  >
  <div
    class="attachment-upload"
    class="attachment-upload"
    [class.justify-content-center]="
      attachment.instances == null || attachment.instances.length == 0
    "
    [class.attachment-required]="
      showValidation &&
      attachment.isRequired &&
      (attachment.instances == null || attachment.instances.length == 0)
    "
  >
    <div *ngIf="attachment?.instances?.length" class="ds-attachment-list-item">
      <div
        class="d-flex flex-gap-1 align-items-center"
        *ngFor="let instance of attachment.instances; let i = index"
      >
        <i class="icon-attach-file-gold2x ds-fs-2"></i>
        <div [innerText]="instance.filename"></div>

        <div class="ml-auto d-flex flex-gap-1">
          <a
            class="ds-btn-icon ds-btn-bg-bright"
            [title]="'trDeleteAttachment' | translate"
            (click)="removeFileInstance(attachment, instance)"
            ><i class="fi-close-thick"></i
          ></a>
          <a
            class="ds-btn-icon ds-btn-bg-bright"
            [title]="'trDownloadAttachment' | translate"
            [href]="link(instance)"
            target="_blank"
          >
            <i class="fi-download fi-large mx-2"></i>
          </a>
        </div>
      </div>
    </div>

    <ngx-file-drop
      *ngIf="attachment.instances?.length < MAX_COUNT_OF_ATTACHMENTS"
      dropZoneLabel=""
      [browseBtnLabel]="'Browse Button'"
      browseBtnClassName="browse-btn-class"
      [multiple]="false"
      accept=".jpg, .png, .jpeg, .gif, .pdf, .bmp, .doc, .docx, .xls, .xlsx"
      [showBrowseBtn]="false"
      (onFileDrop)="onSelectFile($event, attachment)"
    >
      <ng-template
        ngx-file-drop-content-tmp
        let-openFileSelector="openFileSelector"
      >
        <div class="row">
          <div class="col-12">
            <button
              type="button"
              (click)="onUploadButtonClicked(openFileSelector)"
              class="ds-btn ds-btn-secondary"
            >
              <span class="icon-paperclip m-2"></span>
              <span [innerText]="'UploadFile' | translate"></span>
            </button>
          </div>
        </div>
      </ng-template>
    </ngx-file-drop>
  </div>
</div>
