import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WorkflowService } from '../../shared-workflow-object/workflow.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CustomerRequestActionsEnum } from '../../../core/models/common/ServiceMainTransactionData';
import { BaseComponent } from 'src/app/shared/base/BaseComponent';

@AutoUnsubscribe({ arrayName: 'observableSubscriptions' })
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent<T>
  extends BaseComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input('currentStep')
  currentStep: string;

  observableSubscriptions: Subscription[] = [];
  form: FormGroup;
  showAcceptTerms = false;
  showAutoRenewal = false;
  showPayNow: boolean = false;
  showSubmit: boolean = false;
  serviceFees: number;

  globalObject: any;
  currentStateObject: any;
  showSubmitButtonAndHidePayNow: boolean;
  @ViewChild('autofocus') myInputField: ElementRef;

  constructor(
    private router: Router,
    @Inject('WorkflowService')
    public workflowService: WorkflowService<T>,
    private toastr: ToastrService,
    translateService: TranslateService
  ) {
    super(translateService);
  }

  public ngOnInit(): void {
    this.showPayNow = this.workflowService.showPayNow();
    this.showSubmit = this.workflowService.showSubmit();

    this.globalObject = this.workflowService.globalObject;
    this.currentStateObject = this.globalObject[this.currentStep];
    this.serviceFees = this.workflowService.getTotalPayment();
  }

  public removeValidationFromTermsAndConditionField(): void {
    this.form.get('AcceptTerms').clearValidators();
    this.form.get('AcceptTerms').updateValueAndValidity();
  }

  public submit(action: string): void {
    let submitAction: CustomerRequestActionsEnum = (<any>(
      CustomerRequestActionsEnum
    ))[action];
    if (this.workflowService.checkAllPerviousStepsValidation()) {
      this.workflowService.postData(submitAction).subscribe((response) => {
        if (
          submitAction == CustomerRequestActionsEnum.PayNow &&
          response.responseData.paymentChannelTransactionDTO
        ) {
          var paymentUrl =
            response.responseData.paymentChannelTransactionDTO.returnUrl;

          if (this.isArabic) {
            paymentUrl = paymentUrl + '&lang=ar';
          }

          window.open(paymentUrl, '_self');
        } else {
          this.workflowService.globalObject.Response = response.responseData;

          this.workflowService.globalObject.Congratulations.customerPulseLinkingId =
            response.responseData.requestTypeData.customerPulseLinkingId;
          this.workflowService.globalObject.Congratulations.customerPulseLinkingIdForMobile =
            response.responseData.requestTypeData.customerPulseLinkingIdForMobile;
          this.workflowService.globalObject.Congratulations.registerUserId =
            response.responseData.registeredUserId.toString();
          this.workflowService.globalObject.Congratulations.transactionId =
            response.responseData.id.toString();
          this.workflowService.globalObject.Congratulations.isFeedbackDone =
            false;
          this.workflowService.globalObject.Congratulations.serviceRequestNumber =
            response.responseData.serviceRequestNumber;

          this.workflowService.moveForward(this.router);
        }
      });
    } else {
      this.showError(
        this.translateService.instant('YouHaveToCompleteAllPreviousSteps')
      );
    }
  }

  public showError(errorMessage: string) {
    this.toastr.error('', errorMessage);
  }

  /**
   * Move back One step.
   */
  public moveBack() {
    this.workflowService.moveBack(this.router);
  }

  public goToPage(name: string) {
    this.router.navigateByUrl(name);
  }

  public goToExternalPage(url: string) {
    window.location.href = url;
  }

  public ngAfterViewInit() {
    this.myInputField.nativeElement.focus();
  }

  public ngOnDestroy(): void {
    // throw new Error('Method not implemented.');
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.currentStateObject = (<any>this.workflowService.globalObject)[
      this.currentStep
    ];
    this.form = this.currentStateObject.form;

    this.showAcceptTerms = this.workflowService.isShowTermsAndConditions();
    this.showAutoRenewal = this.workflowService.isValidAutoRenewal();
    if (this.showAutoRenewal) {
      (<FormControl>this.form.get('AutoRenewal')).setValidators([
        Validators.requiredTrue,
      ]);
      (<FormControl>this.form.get('AutoRenewal')).updateValueAndValidity();
    } else {
      (<FormControl>this.form.get('AutoRenewal')).clearValidators();
      (<FormControl>this.form.get('AutoRenewal')).updateValueAndValidity();
    }
  }

  public submitWithoutPayment() {
    this.submit(CustomerRequestActionsEnum.SubmitWithoutPayment);
  }

  public payNow() {
    this.submit(CustomerRequestActionsEnum.PayNow.toString());
  }

  public payLater() {
    this.submit(CustomerRequestActionsEnum.PayLater.toString());
  }

  public submitWorkflowAction() {
    if (this.workflowService.submittedActionCode) {
      this.submit(this.workflowService.submittedActionCode.toString());
    } else {
      this.toastr.error(
        this.translateService.instant('YouAccessedTheServiceWithImproperWay')
      );
    }
  }
}
