import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ExportTypeResponse } from '../export/shared/responses/ExportTypeResponse';
import { PortsResponse } from '../export/shared/responses/PortsResponse';
import { EsBaseTemplateService } from './es-base-template.service';
import {
  EsGuidResponse,
  EsProceedPaymentResponse,
} from '../../models/shared/responses/EsProceedPaymentResponse';
import { VessilValidityResponse } from '../export/shared/responses/VessilValidityResponse';
import { EsImportReleaseGetAllowedClassesDto } from './EsGetAllowedClassesRequest';
import { EsImportReleaseGetAllowedClassesResponse } from './EsGetAllowedClassesResponse';
import { GetRequestReceiptResponse } from './GetRequestReceiptResponse';
import { EsCommentPostDto } from './EsCommentPostDto';
import { EsOtpDto } from './EsOtpDto';
import { EsValidateEmiratesIdResponse } from './EsValidateEmiratesIdResponse';
import { EsValidateOtpResponse } from './EsValidateOtpResponse';
import { EsGetCommentsResponse } from './EsGetCommentsResponse';
import {
  EsImportReleaseGetBanDisclaimersDto,
  EsImportReleaseGetJobNumberDto,
  EsImportReleaseGetPortsDto,
  EsImportReleaseGetTypeOfClassDto,
  EsImportReleaseGetVaccineDetailsDto,
} from './EsGetTypeOfImportReleaseClassDto';
import { EsImportReleaseGetTypeOfClassResponse } from './EsGetTypeOfImportReleaseClassResponse';
import { EsImportReleaseBanDisclaimersResponse } from './EsImportReleaseBanDisclaimersResponse';
import { EsImportReleasePortsResponse } from './EsImportReleasePortsResponse';
import { EsImportReleaseJobNumberResponse } from './EsImportReleaseJobNumberResponse';
import { EsCommentResponse } from './EsCommentResponse';
import { PostPheromonesMoreDetailsDto } from './PostPheromonesMoreDetailsDto';
import { EsPheromonesMoreDetailsResponse } from './EsPheromonesMoreDetailsResponse';
import { EsImportReleaseVaccineDetailsResponse } from './EsImportReleaseVaccineDetailsResponse';
import { EsResponseModel } from './EsResponseModel';
import { GetTypeAllowedQuantityForParticipantResponse } from './response/GetTypeAllowedQuantityForParticipantResponse';
import { ImportArrivalDateDto } from './import-release/ImportArrivalDateDto';
import { EsUnconfirmedPoppedUpRequestDto } from './EsUnconfirmedPoppedUpRequestDto';
import { UserProfileDto } from '../../models/common/UserProfileDto';
import { EsSaveAsDraftService } from './save-as-draft/es-save-as-draft.service';
import { SaveAsDraftRequestDto } from './save-as-draft/SaveAsDraftRequest';
import { TemplateServicesService } from '../template-services/template-services.service';
import { ParticipantTemplateDto } from '../export/shared/template-services/TemplateServicesResponse';
import { ImportantLinkDto } from '../../models/common/ImportantLinkDto';
import { HealthCertificateTemplateDto } from '../../models/import/import-animal-birds/HealthCerificateTemplateDto';

@Injectable()
export class EsCommonService extends EsBaseTemplateService {
  readonly basePath = 'CommonServices';

  private REST_API_SERVER_GET_TYPES =
    this.BASE_API + 'CommonServices/GetTypesByClassId?classId={classId}';

  private REST_API_SERVER_GET_PORTS =
    this.BASE_API +
    'CommonServices/GetPortsByShipmentMethodId?shippingMethodId={shippingMethodId}';

  private REST_API_SERVER_POST_PROCEED_PAYMENT =
    this.BASE_API + 'CommonServices/ProceedPayment?checkoutId={checkoutId}';

  private REST_API_GET_CUSTOMER_PULSE_TOKEN =
    this.BASE_API +
    'CommonServices/GetCustomerPulseToken?procedureInstanceID={procedureInstanceId}';

  private REST_API_GENERATE_CUSTOMER_PULSE_TOKEN =
    this.BASE_API +
    'CommonServices/GenerateTransactionEntry?procedureInstanceId={procedureInstanceId}';

  private REST_API_SERVER_GET_VESSIL_VALIDITY =
    this.BASE_API +
    'CommonServices/CheckBoatNumberValidity?boatNumber={boatNumber}';

  private REST_API_COMMENT = this.BASE_API + 'CommonServices/CreateComment';
  private REST_API_VALIDATE_OTP = this.BASE_API + 'CommonServices/ValidateOtp';

  private REST_API_IS_FEEDBACK_DONE =
    this.BASE_API + 'CommonServices/SetFeedbackDone?requestId={requestId}';

  private REST_API_VALIDATE_EMIRATES_ID =
    this.BASE_API + 'CommonServices/ValidateIdNumber?idNumber={idNumber}';

  private REST_API_CANCEL_REQUEST =
    this.BASE_API + 'CommonServices/CancelRequest?requestId={requestId}';

  private REST_API_GET_COMMENTS =
    this.BASE_API + 'CommonServices/GetRequestComments?requestId={requestId}';

  private REST_API_GET_ALLOWED_CLASSES =
    this.BASE_API + 'CommonServices/GetAllowedClassesForIR';

  private REST_API_GET_ALLOWED_TYPES =
    this.BASE_API + 'CommonServices/GetTypesForIRClass';

  private REST_API_GET_BAN_DISCLAIMERS =
    this.BASE_API + 'CommonServices/GetIRBanDisclaimer';

  private REST_API_GET_PORTS = this.BASE_API + 'CommonServices/GetPortsForIR';

  private REST_API_GET_JOB_NUMBER =
    this.BASE_API + 'CommonServices/GetJobNumberDetails';

  private REST_API_GET_PheromonesMoreDetailsByTypeID =
    this.BASE_API + 'CommonServices/GetPheromonesMoreDetailsByTypeID';

  private REST_API_GET_LIVE_ANIMAL_HEALTH_CERTIFICATE_TEMPLATE =
    this.BASE_API + 'CommonServices/GetLiveAnimalHealthCertificateTemplate?classId={classId}&typeId={typeId}';

    private REST_API_GET_RECEIPT =
    this.BASE_API + 'CommonServices/GetRequestReceipts?requestId={requestId}';

  private REST_API_VACCINE_DETAILS =
    this.BASE_API + '/CommonServices/GetVaccineMoreDetailsByTypeID';

  private GET_TYPE_ALLOWED_QUANTITY_FOR_PARTICIPANT =
    'GetTypeAllowedQuantityForParticipant?typeId={typeId}&requestId={requestId}';

  private UPDATE_ARRIVAL_DATE = 'UpdateArrivalDataForIR';
  private HAS_CONFIRMED_REQUEST_FOR_IR =
    'HasConfirmedRequestForIR?procedureInstanceId={requestId}';

  private _GetAllowedPorts = this.BASE_API + '/CommonServices/GetAllowedPorts';

  private HAS_UNCONFIRMED_POPPED_UP_REQUEST = '/HasUnconfirmedPoppedUpRequest';
  private CONFIRM_ON_CALL_REQUEST_POPUP =
    '/ConfirmOnCallRequestPopUp?confirmationId={confirmationId}';

  constructor(
    private httpClient: HttpClient,
    private saveAsDraftService: EsSaveAsDraftService,
    private templateServicesService: TemplateServicesService
  ) {
    super();
  }

  getTypesByClassId(classId: number): Observable<ExportTypeResponse> {
    return this.httpClient
      .get<ExportTypeResponse>(
        this.REST_API_SERVER_GET_TYPES.replace('{classId}', classId.toString())
      )
      .pipe(catchError(this.handleError));
  }

  getPortsByShippingMethodId(
    shippingMethodId: number
  ): Observable<PortsResponse> {
    return this.httpClient
      .get<PortsResponse>(
        this.REST_API_SERVER_GET_PORTS.replace(
          '{shippingMethodId}',
          shippingMethodId.toString()
        )
      )
      .pipe(catchError(this.handleError));
  }

  proceedPayment(checkoutId: string): Observable<EsProceedPaymentResponse> {
    return this.httpClient
      .post<EsProceedPaymentResponse>(
        this.REST_API_SERVER_POST_PROCEED_PAYMENT.replace(
          '{checkoutId}',
          checkoutId
        ),
        {}
      )
      .pipe(catchError(this.handleError));
  }

  public getCustomerPulseToken(
    procedureInstanceId: string
  ): Observable<EsGuidResponse> {
    return this.httpClient
      .get<EsGuidResponse>(
        this.REST_API_GET_CUSTOMER_PULSE_TOKEN.replace(
          '{procedureInstanceId}',
          procedureInstanceId
        )
      )
      .pipe(catchError(this.handleError));
  }

  vessilValidity(fishingVessil: string) {
    return this.httpClient
      .get<VessilValidityResponse>(
        this.REST_API_SERVER_GET_VESSIL_VALIDITY.replace(
          '{boatNumber}',
          fishingVessil
        )
      )
      .pipe(catchError(this.handleError));
  }

  public createComment(comment: EsCommentPostDto) {
    return this.httpClient
      .post<EsCommentResponse>(this.REST_API_COMMENT, comment)
      .pipe(catchError(this.handleError));
  }

  public validateOtp(otpDetails: EsOtpDto): Observable<EsValidateOtpResponse> {
    return this.httpClient
      .post<EsValidateOtpResponse>(this.REST_API_VALIDATE_OTP, otpDetails)
      .pipe(catchError(this.handleError));
  }

  public setIsFeedbackDone(procedureInstanceId: number) {
    return this.httpClient
      .get(
        this.REST_API_IS_FEEDBACK_DONE.replace(
          '{requestId}',
          procedureInstanceId.toString()
        )
      )
      .pipe(catchError(this.handleError));
  }

  public validateEmiratesId(
    emiratesId: string
  ): Observable<EsValidateEmiratesIdResponse> {
    return this.httpClient
      .get<EsValidateEmiratesIdResponse>(
        this.REST_API_VALIDATE_EMIRATES_ID.replace(
          '{idNumber}',
          emiratesId.toString()
        )
      )
      .pipe(catchError(this.handleError));
  }

  public cancelRequest(
    requestId: number
  ): Observable<EsValidateEmiratesIdResponse> {
    return this.httpClient
      .get<EsValidateEmiratesIdResponse>(
        this.REST_API_CANCEL_REQUEST.replace(
          '{requestId}',
          requestId.toString()
        )
      )
      .pipe(catchError(this.handleError));
  }

  public getRequestComments(
    requestId: number
  ): Observable<EsGetCommentsResponse> {
    return this.httpClient
      .get<EsGetCommentsResponse>(
        this.REST_API_GET_COMMENTS.replace('{requestId}', requestId.toString())
      )
      .pipe(catchError(this.handleError));
  }

  public getImportReleaseAllowedClasses(
    request: EsImportReleaseGetAllowedClassesDto
  ): Observable<EsImportReleaseGetAllowedClassesResponse> {
    return this.httpClient
      .post<EsImportReleaseGetAllowedClassesResponse>(
        this.REST_API_GET_ALLOWED_CLASSES,
        request
      )
      .pipe(catchError(this.handleError));
  }

  public getImportReleaseTypeOfClass(
    request: EsImportReleaseGetTypeOfClassDto
  ): Observable<EsImportReleaseGetTypeOfClassResponse> {
    return this.httpClient
      .post<EsImportReleaseGetTypeOfClassResponse>(
        this.REST_API_GET_ALLOWED_TYPES,
        request
      )
      .pipe(catchError(this.handleError));
  }

  public getImportReleaseBanDisclaimers(
    request: EsImportReleaseGetBanDisclaimersDto
  ): Observable<EsImportReleaseBanDisclaimersResponse> {
    return this.httpClient
      .post<EsImportReleaseBanDisclaimersResponse>(
        this.REST_API_GET_BAN_DISCLAIMERS,
        request
      )
      .pipe(catchError(this.handleError));
  }

  public getImportReleasePorts(
    request: EsImportReleaseGetPortsDto
  ): Observable<EsImportReleasePortsResponse> {
    return this.httpClient
      .post<EsImportReleasePortsResponse>(this.REST_API_GET_PORTS, request)
      .pipe(catchError(this.handleError));
  }

  public getImportReleaseJobNumber(
    request: EsImportReleaseGetJobNumberDto
  ): Observable<EsImportReleaseJobNumberResponse> {
    return this.httpClient
      .post<EsImportReleaseJobNumberResponse>(
        this.REST_API_GET_JOB_NUMBER,
        request
      )
      .pipe(catchError(this.handleError));
  }

  public getPheromonesMoreDetailsByTypeId(
    request: PostPheromonesMoreDetailsDto
  ): Observable<EsPheromonesMoreDetailsResponse> {
    return this.httpClient
      .post<EsPheromonesMoreDetailsResponse>(
        this.REST_API_GET_PheromonesMoreDetailsByTypeID,
        request
      )
      .pipe(catchError(this.handleError));
  }

  public getRequestReceipt(
    requestId: number
  ): Observable<GetRequestReceiptResponse> {
    return this.httpClient
      .get<GetRequestReceiptResponse>(
        this.REST_API_GET_RECEIPT.replace('{requestId}', requestId.toString())
      )
      .pipe(catchError(this.handleError));
  }

  public getLiveAnimalHealthCertificateTemplate(
    classId: number,
    typeId:number
  ): Observable<any> {
    return this.httpClient
      .get<any>(
        this.REST_API_GET_LIVE_ANIMAL_HEALTH_CERTIFICATE_TEMPLATE
        .replace('{classId}', classId.toString())
        .replace('{typeId}', typeId.toString())
      )
      .pipe(catchError(this.handleError));
  }

  public getImportReleaseVaccineDetails(
    request: EsImportReleaseGetVaccineDetailsDto
  ): Observable<EsImportReleaseVaccineDetailsResponse> {
    return this.httpClient
      .post<EsImportReleaseVaccineDetailsResponse>(
        this.REST_API_VACCINE_DETAILS,
        request
      )
      .pipe(catchError(this.handleError));
  }

  public getTypeAllowedQuantityForParticipant(
    typeId: number,
    requestId: number
  ): Observable<GetTypeAllowedQuantityForParticipantResponse> {
    return this.httpClient
      .get<GetTypeAllowedQuantityForParticipantResponse>(
        this.buildUrl(this.GET_TYPE_ALLOWED_QUANTITY_FOR_PARTICIPANT)
          .replace('{requestId}', requestId.toString())
          .replace('{typeId}', typeId.toString())
      )
      .pipe(catchError(this.handleError));
  }

  public getAllowedPorts(
    request: EsImportReleaseGetPortsDto
  ): Observable<EsImportReleasePortsResponse> {
    return this.httpClient
      .post<EsImportReleasePortsResponse>(this._GetAllowedPorts, request)
      .pipe(catchError(this.handleError));
  }

  public updateArrivalDate(
    request: ImportArrivalDateDto
  ): Observable<EsResponseModel<boolean>> {
    return this.httpClient
      .post<EsResponseModel<boolean>>(
        this.buildUrl(this.UPDATE_ARRIVAL_DATE),
        request
      )
      .pipe(catchError(this.handleError));
  }

  public hasConfirmedRequest(
    requestId: number
  ): Observable<EsResponseModel<boolean>> {
    return this.httpClient
      .get<EsResponseModel<boolean>>(
        this.buildUrl(this.HAS_CONFIRMED_REQUEST_FOR_IR).replace(
          '{requestId}',
          requestId.toString()
        )
      )
      .pipe(catchError(this.handleError));
  }

  public getUnconfirmedPoppedUpRequest(): Observable<
    EsResponseModel<EsUnconfirmedPoppedUpRequestDto>
  > {
    return this.httpClient
      .get<EsResponseModel<EsUnconfirmedPoppedUpRequestDto>>(
        this.buildUrl(this.HAS_UNCONFIRMED_POPPED_UP_REQUEST)
      )
      .pipe(catchError(this.handleError));
  }

  public confirmOnCallRequestPopUp(
    confirmationId: number
  ): Observable<EsResponseModel<boolean>> {
    const url = this.buildUrl(
      this.CONFIRM_ON_CALL_REQUEST_POPUP.replace(
        '{confirmationId}',
        confirmationId.toString()
      )
    );

    return this.httpClient
      .put<EsResponseModel<boolean>>(url, null)
      .pipe(catchError(this.handleError));
  }

  public getUserAccounts(): Observable<EsResponseModel<UserProfileDto[]>> {
    return this.httpClient
      .get<EsResponseModel<UserProfileDto[]>>(
        this.BASE_API + 'Common/GetUserAccounts'
      )
      .pipe(catchError(this.handleError));
  }

  public switchUserAccount(
    userId: number
  ): Observable<EsResponseModel<string>> {
    return this.httpClient
      .post<EsResponseModel<string>>(
        this.BASE_API + 'Common/SwitchUserAccount',
        userId
      )
      .pipe(catchError(this.handleError));
  }

  public saveAsDraft(request: SaveAsDraftRequestDto) {
    return this.saveAsDraftService.saveAsDraft(request);
  }

  public saveTemplate(request: ParticipantTemplateDto) {
    return this.templateServicesService.addOrUpdate(request);
  }

  public getTemplate(templateId: string) {
    return this.templateServicesService.get(templateId);
  }

  public getDraftRequest(draftRequestId: string) {
    return this.saveAsDraftService.getDraftRequest(draftRequestId);
  }

  public cancelDraftRequest(draftRequestId: string) {
    return this.saveAsDraftService.cancelDraftRequest(draftRequestId);
  }

  public getImportantLinks(): Observable<EsResponseModel<ImportantLinkDto>> {
    return this.httpClient
      .get<EsResponseModel<ImportantLinkDto>>(
        this.BASE_API + 'Common/GetImportantLinks'
      )
      .pipe(catchError(this.handleError));
  }

  public generateTransactionEntry(
    procedureInstanceId: string
  ): Observable<EsGuidResponse> {
    return this.httpClient
      .get<EsGuidResponse>(
        this.REST_API_GENERATE_CUSTOMER_PULSE_TOKEN.replace(
          '{procedureInstanceId}',
          procedureInstanceId
        )
      )
      .pipe(catchError(this.handleError));
  }
}
